.header-container--bottom-left {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
}

.header-container--bottom-right {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.header-container--top-left {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
  padding-top: 2rem;
}

.header-container--top-right {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 2rem;
}

.header--md {
  z-index: 0;
  font-size: 6rem;
  font-weight: lighter;
  letter-spacing: -1.5px;
  color: #2d3436;
}

.header--lg {
  z-index: 0;
  font-size: 9rem;
  font-weight: lighter;
  letter-spacing: -1.5px;
  color: #2d3436;
}

.header--xl {
  z-index: 0;
  font-size: 12rem;
  font-weight: lighter;
  letter-spacing: -1.5px;
  color: #2d3436;
}
