.contact-bkg {
  background-color: #121212;
  color: #dfe6e9;
  padding: 0;
}

.contact-outer-container--md {
  z-index: 1;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 2rem;
  width: 400px;
  height: 400px;
}

.contact-outer-container--lg {
  z-index: 1;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 2rem;
  width: 477px;
  height: 477px;
}

.contact-outer-container--xl {
  z-index: 1;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 2rem;
  width: 648px;
  height: 648px;
}

.resume-surface {
  grid-row: 1/4;
  grid-column: 1;
  justify-self: flex-end;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 60%;
  background-color: rgba(255, 255, 255, 0.05);
  transition: transform 300ms ease, opacity 300ms ease, border-radius 300ms ease;
  cursor: pointer;
  opacity: 0;
  border-radius: 50%;
}

.resume-surface:hover {
  transform: scale(1.075);
}

.other-contact-surface {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgba(255, 255, 255, 0.05);
  transition: transform 300ms ease, opacity 300ms ease, border-radius 300ms ease;
  cursor: pointer;
  opacity: 0;
  border-radius: 50%;
}

.other-contact-surface:hover {
  transform: scale(1.075);
}

.contact-surface-appear {
  opacity: 1;
  border-radius: 0;
}

.icon--md {
  width: 32px;
  height: 32px;
  margin-bottom: 0.5rem;
}

.icon--lg {
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
}

.icon--xl {
  width: 64px;
  height: 64px;
  margin-bottom: 0.5rem;
}

.surface-text--md {
  font-size: 1.25rem;
  letter-spacing: 0.15px;
}
.surface-text--lg {
  font-size: 1.5rem;
  letter-spacing: 0px;
}
.surface-text--xl {
  font-size: 1.5rem;
  letter-spacing: 0px;
}
