.projects-bkg {
  background-color: #121212;
  color: #dfe6e9;
  padding: 0;
}

.projects-container--md {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  width: 768px;
  margin-bottom: 1rem;
}

.projects-container--lg {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  width: 1024px;
  margin-bottom: 1rem;
}

.projects-container--xl {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  width: 1536px;
  margin-bottom: 2rem;
}

.stepper-container {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.dot--displayed {
  width: 1rem;
  height: 1rem;
  border: 2px solid #dfe6e9;
  background-color: #dfe6e9;
  border-radius: 100%;
  transition: background-color 100ms;
}

.dot--not-displayed {
  width: 1rem;
  height: 1rem;
  border: 2px solid #dfe6e9;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 100%;
  transition: background-color 100ms;
}

.stepper-btn {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transition: transform 100ms;
}

.stepper-btn:hover {
  transform: scale(1.25);
}

.content-container--md {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  width: 100%;
}

.content-container--lg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  width: 100%;
}

.content-container--xl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  width: 100%;
}

.project-img-container--md {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  opacity: 0;
  transform: translateY(2rem);
}

.project-img-container--lg {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  opacity: 0;
  transform: translateY(2rem);
}

.project-img-container--xl {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  opacity: 0;
  transform: translateY(2rem);
}

.project-img--md {
  object-fit: contain;
  width: 100%;
  max-height: 250px;
  box-sizing: border-box;
}

.project-img--lg {
  object-fit: contain;
  width: 100%;
  max-height: 300px;
  box-sizing: border-box;
}

.project-img--xl {
  object-fit: contain;
  width: 100%;
  max-height: 450px;
  box-sizing: border-box;
}

.content-description-container--md {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content-description-container--lg {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 80%;
}

.content-description-container--xl {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 80%;
}

.content-description--md {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  padding-top: 3rem;
  box-sizing: border-box;
  opacity: 0;
}

.content-description--lg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  padding-top: 3rem;
  box-sizing: border-box;
  opacity: 0;
}

.content-description--xl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  padding-top: 3rem;
  box-sizing: border-box;
  opacity: 0;
}

.content-surface {
  z-index: 0;
  position: absolute;
  align-self: flex-end;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  transition: transform 300ms;
}

.content-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.content-title--md {
  font-size: 1.5rem;
  letter-spacing: 0px;
}

.content-title--lg {
  font-size: 1.5rem;
  letter-spacing: 0px;
}

.content-title--xl {
  font-size: 2.125rem;
  letter-spacing: 0.25px;
}

.content-date--md {
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  font-style: italic;
  line-height: 1.5rem;
}

.content-date--lg {
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  font-style: italic;
  line-height: 1.5rem;
}

.content-date--xl {
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  font-style: italic;
  line-height: 1.5rem;
}

.content-text--md {
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  line-height: 1rem;
}

.content-text--lg {
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  line-height: 1rem;
}

.content-text--xl {
  font-size: 1rem;
  letter-spacing: 0.15px;
  line-height: 1.125rem;
}

.content-link--md {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.content-link--lg {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.content-link--xl {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.content-expand {
  text-decoration: underline;
  cursor: pointer;
}

.content .github-link {
  position: relative;
  font-size: 1rem;
  letter-spacing: 0.15px;
  text-decoration: underline;
  cursor: pointer;
  opacity: 0;
  top: 2rem;
  animation: fadeInUp 750ms ease;
  -o-animation: fadeInUp 750ms ease;
  -moz-animation: fadeInUp 750ms ease;
  -webkit-animation: fadeInUp 750ms ease;
  animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 1900ms;
  -o-animation-delay: 1900ms;
  -moz-animation-delay: 1900ms;
  -webkit-animation-delay: 1900ms;
}

.fade-in-anims {
  animation: fadeIn 300ms ease;
  animation-fill-mode: forwards;
  animation-delay: 300ms;
}

.fade-out-anims {
  animation: fadeOut 100ms ease;
  animation-fill-mode: forwards;
}

.next-exit-anims {
  animation: fadeOutToLeft 100ms ease;
  animation-fill-mode: forwards;
}

.next-enter-anims {
  animation: fadeInFromRight 300ms ease;
  animation-fill-mode: forwards;
}

.back-exit-anims {
  animation: fadeOutToRight 100ms ease;
  animation-fill-mode: forwards;
}

.back-enter-anims {
  animation: fadeInFromLeft 300ms ease;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    left: 1rem;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeOutToLeft {
  0% {
    opacity: 1;
    right: 0;
  }
  100% {
    opacity: 0;
    right: 1rem;
  }
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    right: 1rem;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes fadeOutToRight {
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: 1rem;
  }
}
