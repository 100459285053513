.intro-bkg {
  background-color: #121212;
  color: #dfe6e9;
  padding: 0;
}

.intro-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-container.xl {
  width: 1536px;
  font-size: 5.9rem;
  font-weight: normal;
  letter-spacing: -1.5px;
  line-height: 6.125rem;
}

.intro-container.lg {
  width: 1024px;
  font-size: 4rem;
  font-weight: normal;
  letter-spacing: -1.5px;
  line-height: 4.125rem;
}

.intro-container.md {
  width: 768px;
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: -1.5px;
  line-height: 3.125rem;
}

.intro-container.sm {
  width: 480px;
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: -1.5px;
  line-height: 2.125rem;
}

.intro-container.xs {
  width: 80%;
  font-size: 5vw;
  letter-spacing: -1.5px;
}

.intro-container-text-line-1 {
  opacity: 0;
  animation: introTextAnims 1000ms ease;
  animation-delay: 1500ms;
  animation-fill-mode: forwards;
}

.intro-container-text-line-2 {
  opacity: 0;
  animation: introTextAnims 1000ms ease;
  animation-delay: 2500ms;
  animation-fill-mode: forwards;
}

.intro-container-borderanim {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid white;
  align-self: flex-start;
  animation: introBorderAnims 1250ms ease, introBorderAnimsDisappear 1000ms ease;
  animation-delay: 0ms, 3500ms;
  animation-fill-mode: forwards;
}

.clr-blue {
  color: #0984e3;
}

/* Animations */
@keyframes introBorderAnims {
  0% {
    width: 0%;
    height: 0%;
    opacity: 0;
  }
  5% {
    width: 0%;
    height: 0%;
    opacity: 1;
  }
  30% {
    width: 0%;
    height: 100%;
    opacity: 1;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

@keyframes introBorderAnimsDisappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes introTextAnims {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes navbarSelectionAnims {
  0% {
    opacity: 0;
    top: 1rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
