.about-bkg {
  background-color: #121212;
  color: #dfe6e9;
  padding: 0;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-container.xl {
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  width: 660px;
}

.about-container.lg {
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  width: 660px;
}

.about-container.md {
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  width: 660px;
}

.about-container.sm {
  font-size: 2vw;
  letter-spacing: 0.25px;
  width: 50ch;
}

.about-container.xs {
  font-size: 2vw;
  letter-spacing: 0.25px;
  width: 50ch;
}

.about-p1 {
  position: relative;
  opacity: 0;
  margin-bottom: 2rem;
}

.about-p2 {
  position: relative;
  opacity: 0;
}

.about-p-anims {
  animation: fadeInUp 750ms ease;
  -o-animation: fadeInUp 750ms ease;
  -moz-animation: fadeInUp 750ms ease;
  -webkit-animation: fadeInUp 750ms ease;
  animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    top: 2rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    top: 2rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    top: 2rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    top: 2rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
