.navbar {
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  background-color: #121212;
  color: #dfe6e9;
}

.navbar-selection {
  font-family: monospace;
  font-size: 1.25rem;
  font-weight: lighter;
  letter-spacing: 0px;
  cursor: pointer;
}
