.section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* Typography */
.h1 {
  font-size: 6rem;
  font-weight: lighter;
  letter-spacing: -1.5px;
}

.h2 {
  font-size: 3.75rem;
  font-weight: lighter;
  letter-spacing: -0.5px;
}

.h3 {
  font-size: 3rem;
  letter-spacing: 0px;
}

.h4 {
  font-size: 2.125rem;
  letter-spacing: 0.25px;
}

.h5 {
  font-size: 1.5rem;
  letter-spacing: 0px;
}

.h6 {
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.15px;
}

.subtitle1 {
  font-size: 1rem;
  letter-spacing: 0.15px;
}

.subtitle2 {
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.1px;
}

.body1 {
  font-size: 1rem;
  letter-spacing: 0.5px;
}

.body2 {
  font-size: 0.875rem;
  letter-spacing: 0.25px;
}

.button {
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.caption {
  font-size: 0.75rem;
  letter-spacing: 0.4px;
}

.overline {
  font-size: 0.625rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.underlined {
  text-decoration: underline;
}

/* Paddings & Margins */
.mb-8 {
  margin-bottom: 0.5rem;
}

.mb-16 {
  margin-bottom: 1rem;
}

.mb-32 {
  margin-bottom: 2rem;
}

.mb-48 {
  margin-bottom: 3rem;
}

.mb-64 {
  margin-bottom: 4rem;
}

.mr-4 {
  margin-right: 0.5rem;
}

.mr-8 {
  margin-right: 1rem;
}

.mr-16 {
  margin-right: 1rem;
}

.mr-32 {
  margin-right: 2rem;
}

.mr-48 {
  margin-right: 3rem;
}

.mr-128 {
  margin-right: 8rem;
}

.ml-16 {
  margin-left: 1rem;
}

/* Debugging */
.border1 {
  border: 1px solid white;
}

.border2 {
  border: 1px solid red;
}

/* Global styles */
p {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Georgia", serif;
}
